/*=========================================================================================
  File Name: modulePatientActions.js
  Description: Patient Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
    AddPatient({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/Patient/AddPatient", item)
                .then(response => {
                    commit(
                        "ADD_ITEM",
                        Object.assign(item, { ID: response.data.Data.ID })
                    );
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    SearchPatientReservationSurgerys({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSurgery/SearchPatientReservationSurgerys",
                    item
                )
                .then(response => {
                    commit("SET_Patient", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    AddPatientReservationSurgeryComment({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("Api/PatientReservationSurgeryComment/AddPatientReservationSurgeryComment", item)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetPatientReservationSurgery(context, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientReservationSurgery/GetPatientReservationSurgery?reservationSurgeryID=" +
                    id
                )
                .then(response => {
                    // commit('REMOVE_ITEM', item)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }, 
    GetPatientReservationSurgeryDocuments({ commit }, id) {
        debugger 
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/PatientReservationSurgery/GetPatientReservationSurgeryWithDocument?reservationSurgeryID=" +
                    id
                )
                .then(response => {
                    debugger
                    // commit('REMOVE_ITEM', item)
                    resolve(response);
                    debugger
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllStatuses(Context) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Status/GetAllStatuses")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    ChangePatientReservationSurgeryStatus(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSurgery/ChangePatientReservationSurgeryStatus",
                    item
                )
                .then(response => {
                    // commit('REMOVE_ITEM', item)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UpdatePatientReservationSurgery(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSurgery/UpdatePatientReservationSurgery",
                    item
                )
                .then(response => {
                    // commit('REMOVE_ITEM', item)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    UpdatePatient({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/api/Patient/UpdatePatient", item)
                .then(response => {
                    commit("UPDATE_Patient", item);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetPatient(Context) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Patient/GetAllPatients")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetAllStatuses(Context) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Status/GetAllStatuses")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllPaymentStatuses(Context) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/PaymentStatus/GetAllPaymentStatuses")
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

};