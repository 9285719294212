<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-1"
      collapse-action
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/5 sm:w-1/2  w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date From") }}</label>
          <datepicker
            placeholder="from"
            v-model="search.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date To") }}</label>
          <datepicker
            placeholder="from"
            v-model="search.DateTo"
            class=" w-full"
            name="from"
          ></datepicker>
        </div>

        <!-- <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-5">
          <label class="text-sm opacity-75">{{ $t("TimeFrom") }}</label>
          <vs-input
            v-model="search.TimeFrom"
            class="vx-col  w-full"
            type="time"
          />
        </div>

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-5">
          <label class="text-sm opacity-75">{{ $t("TimeTo") }}</label>
          <vs-input
            v-model="search.TimeTo"
            class="vx-col  w-full"
            type="time"
          />
        </div> -->

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("doctors") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="search.DoctorID"
            :options="doctors"
            :reduce="ID => ID.ID"
          />
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Patients") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="search.PatientID"
            :options="Patients"
            :reduce="ID => ID.ID"
          />
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="search.StatusID"
            :options="Status"
            :reduce="ID => ID.ID"
          />
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Payment Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="search.PaymentStatusID"
            :options="PaymentStatuses"
            :reduce="ID => ID.ID"
          />
        </div>

        <div class="vx-col  w-full">
          <div class="vx-row">
            <vs-button
              style="margin:10px"
              class="my-3"
              @click="doctorSessionSearch"
              v-scroll-to="'#Scroll'"
              >{{ $t("Search") }}</vs-button
            >
            <vs-button
              style="margin-left:10px"
              class="my-3"
              @click="resetData"
              >{{ $t("Reset") }}</vs-button
            >
          </div>
        </div>
      </div>
    </vx-card>

    <vs-table
      id="Scroll"
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="Reservations"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <!-- <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="addNewData"
          >{{ $t("AddNew") }}</vs-button
        > -->
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                Reservations.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : Reservations.length
              }}
              of {{ Reservations.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="Doctor">{{ $t("Patient") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Doctor Comment") }}</vs-th>
        <vs-th sort-key="TimeFrom">{{ $t("Symptom Note") }}</vs-th>
        <vs-th sort-key="TimeTo">{{ $t("Status") }}</vs-th>

        <vs-th>{{ $t("Action") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p v-if="tr.Patient" class="product-name font-medium truncate">
                {{ tr.Patient.Name }}
              </p>
            </vs-td>

            <vs-td>
              <p v-if="tr.DayUTC" class="product-name font-medium truncate">
                <!-- {{ tr.DayUTC | formatDate() }} -->
                {{ tr.DoctorComment }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.SymptomNote }}
              </p>
            </vs-td>
            <vs-td>
              <p v-if="tr.Status" class="product-name font-medium truncate">
                {{ tr.Status.Name }}
              </p>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <router-link
                :to="{
                  name: 'ReservationSurgery-Details',
                  params: { ID: tr.ID }
                }"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
              >
                <feather-icon
                  icon="ArchiveIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                />
              </router-link>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moduleReservation from "@/store/reservation/ReservationSurgery/moduleReservation.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker
  },
  data() {
    return {
      selected: [],
      search: {},
      Patients: [],
      Status: [],
      PaymentStatuses: [],
      itemsPerPage: 10,
      isMounted: false,
      rowDataForDelete: {},
      activeConfirm: false
    };
  },
  computed: {
    isFormValid() {
      return !this.errors.any() && this.Model.StatusID != null;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Reservations() {
      return this.$store.state.ReservationList.Reservations;
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    }
  },

  methods: {
    resetData() {
      this.search = {};
    },
    doctorSessionSearch() {
      this.$store.dispatch(
        "ReservationList/SearchPatientReservationSurgerys",
        this.search
      );
    },

    // deleteData(data) {
    //   this.$store
    //     .dispatch("ReservationList/DeleteDoctorSession", data)
    //     .then(() => {
    //       window.showDeleteSuccess();
    //     })
    //     .catch(err => {
    //       console.error(err);
    //     });
    // },
    GetPatient() {
      this.$store
        .dispatch("ReservationList/GetPatient")
        .then(res => {
          this.Patients = res.data.Data;
          // window.showDeleteSuccess();
        })
        .catch(err => {
          console.error(err);
        });
    },
    GetAllStatuses() {
      this.$store
        .dispatch("ReservationList/GetAllStatuses")
        .then(res => {
          this.Status = res.data.Data;
        })
        .catch(err => {
          console.error(err);
        });
    },
    GetAllPaymentStatuses() {
      this.$store
        .dispatch("ReservationList/GetAllPaymentStatuses")
        .then(res => {
          this.PaymentStatuses = res.data.Data;
        })
        .catch(err => {
          console.error(err);
        });
    },

    editData(data) {
      this.$router.push("/ReservationSurgery-Details");
    }

    // openConfirm(data) {
    //   this.rowDataForDelete = data;
    //   this.$vs.dialog({
    //     type: "confirm",
    //     color: "danger",
    //     title: this.$t("Confirm"),
    //     text: this.$t("DeleteConfirmText"),
    //     accept: this.acceptAlert,
    //     acceptText: this.$t("Delete"),
    //     cancelText: this.$t("Cancel")
    //   });
    // },

    // acceptAlert() {
    //   this.deleteData(this.rowDataForDelete);
    // }
  },

  created() {
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }

    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationList", moduleReservation);
      moduleReservation.isRegistered = true;
    }
    this.$store.dispatch("DoctorList/GetAllDoctors", {});
    this.search.DateFrom =
      new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-1";

    this.search.DateTo =
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0
      ).getDate();
    this.$store.dispatch(
      "ReservationList/SearchPatientReservationSurgerys",
      this.search
    );
    this.GetPatient();
    this.GetAllStatuses();
    this.GetAllPaymentStatuses();
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
