/*=========================================================================================
  File Name: moduleActivityState.js
  Description: Activity Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
 
export default {
  // patients:[],
  Reservations: [],
  SearchObj:{
    DateFrom:new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-1",
    DateTo:new Date().getFullYear() +
    "-" +
    (new Date().getMonth() + 1) +
    "-" +
    new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    ).getDate(),
    BookingTypeID:1,
    DoctorID:'',
    PatientID:'',
    HospitalID:'',
    StatusID:null,
    PaymentStatusID:'',
    IsPendingPayment:'',
    IsPendingDocument:''
  }
};
